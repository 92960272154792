/**
 * @generated SignedSource<<fe2e75bc951f1f01b8908152c7d338c7>>
 * @relayHash 0073d785c3eb69f07cfabd1151ab63b1
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/477.0.0-2024-08-23T22:21:23.075Z/userProfileMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type MeasurementUnit = "CM" | "IN" | "%future added value";
export type EditPersonalProfileInput = {
  clientMutationId?: string | null;
  communicationPreferredLocale?: string | null;
  currency?: Currency | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  measurementUnit?: MeasurementUnit | null;
  preferredLocale?: string | null;
  shareActivities?: boolean | null;
  userServiceId: string;
  zipCode?: string | null;
};
export type userProfileMutation$variables = {
  input: EditPersonalProfileInput;
};
export type userProfileMutation$data = {
  readonly editPersonalProfile: {
    readonly user: {
      readonly preferences: {
        readonly currency: Currency | null;
        readonly measurementUnit: string | null;
        readonly preferredLocale: string | null;
      } | null;
      readonly profile: {
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly zipCode: string | null;
      } | null;
    } | null;
  } | null;
};
export type userProfileMutation = {
  response: userProfileMutation$data;
  variables: userProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Profile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "measurementUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredLocale",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPersonalProfilePayload",
        "kind": "LinkedField",
        "name": "editPersonalProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditPersonalProfilePayload",
        "kind": "LinkedField",
        "name": "editPersonalProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/477.0.0-2024-08-23T22:21:23.075Z/userProfileMutation",
    "metadata": {},
    "name": "userProfileMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "4571bdb654558a01102dab9fe155d41d";

export default node;
