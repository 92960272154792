/**
 * @generated SignedSource<<1f54495a513442d7901ce6b23587357c>>
 * @relayHash 8d34f5d17824d44087a7b5bc65436bd7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/477.0.0-2024-08-23T22:21:23.075Z/initializeTrackingQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type DesignProfileStatus = "ACTIVE" | "DELETED" | "NEW" | "PENDING" | "%future added value";
export type initializeTrackingQuery$variables = {
  fetchTrackingGdprInfo?: boolean | null;
  hasUserId: boolean;
  userCountryCode?: string | null;
  userId: string;
};
export type initializeTrackingQuery$data = {
  readonly viewer: {
    readonly gdprDisplayInfo?: {
      readonly isGdprApplicable: boolean | null;
    } | null;
    readonly transactionsCounter?: {
      readonly confirmedTransactions: number | null;
      readonly submittedTransactions: number | null;
    } | null;
    readonly user?: {
      readonly activePromotion: {
        readonly buyerDisplayable: boolean | null;
        readonly campaignName: string | null;
      } | null;
      readonly authMetadata: {
        readonly emailOnly: boolean | null;
        readonly hasPassword: boolean | null;
      } | null;
      readonly createdDate: string | null;
      readonly isVerifiedTrade: boolean | null;
      readonly isVip: boolean | null;
      readonly preferences: {
        readonly currency: Currency | null;
      } | null;
      readonly profile: {
        readonly email: string | null;
        readonly emailMd5: string | null;
        readonly emailSha256: string | null;
        readonly zipCode: string | null;
      } | null;
      readonly serviceId: string | null;
      readonly totalMessagesToDealers: number | null;
      readonly tradeFirm: {
        readonly profileStatus: DesignProfileStatus | null;
        readonly rewards: {
          readonly currentProgram: {
            readonly programInfo: {
              readonly code: string | null;
            } | null;
          } | null;
        } | null;
        readonly serviceId: string | null;
      } | null;
      readonly verifiedTradeStatus: string | null;
    } | null;
  };
};
export type initializeTrackingQuery = {
  response: initializeTrackingQuery$data;
  variables: initializeTrackingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": true,
  "kind": "LocalArgument",
  "name": "fetchTrackingGdprInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "buyerId",
      "variableName": "userId"
    }
  ],
  "concreteType": "TransactionsCounter",
  "kind": "LinkedField",
  "name": "transactionsCounter",
  "plural": false,
  "selections": [
    {
      "alias": "submittedTransactions",
      "args": null,
      "kind": "ScalarField",
      "name": "submitted",
      "storageKey": null
    },
    {
      "alias": "confirmedTransactions",
      "args": null,
      "kind": "ScalarField",
      "name": "confirmed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVip",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVerifiedTrade",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verifiedTradeStatus",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailOnly",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPassword",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Profile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailMd5",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailSha256",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalMessagesToDealers",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileStatus",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "campaignName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buyerDisplayable",
  "storageKey": null
},
v20 = {
  "condition": "fetchTrackingGdprInfo",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "gdprDisplayInfo",
      "kind": "LinkedField",
      "name": "gdprDisplayInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "countryCode",
              "variableName": "userCountryCode"
            }
          ],
          "kind": "ScalarField",
          "name": "isGdprApplicable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "initializeTrackingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserExistsMetadataType",
                    "kind": "LinkedField",
                    "name": "authMetadata",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmType",
                    "kind": "LinkedField",
                    "name": "tradeFirm",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParentProgram",
                            "kind": "LinkedField",
                            "name": "currentProgram",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LoyaltyProgram",
                                "kind": "LinkedField",
                                "name": "programInfo",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promotion",
                    "kind": "LinkedField",
                    "name": "activePromotion",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "initializeTrackingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserExistsMetadataType",
                    "kind": "LinkedField",
                    "name": "authMetadata",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeFirmType",
                    "kind": "LinkedField",
                    "name": "tradeFirm",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TradeFirmRewards",
                        "kind": "LinkedField",
                        "name": "rewards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ParentProgram",
                            "kind": "LinkedField",
                            "name": "currentProgram",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LoyaltyProgram",
                                "kind": "LinkedField",
                                "name": "programInfo",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v21/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Promotion",
                    "kind": "LinkedField",
                    "name": "activePromotion",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v20/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/477.0.0-2024-08-23T22:21:23.075Z/initializeTrackingQuery",
    "metadata": {},
    "name": "initializeTrackingQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "45613ed57f3e92ad1bcba8aa7880ab1f";

export default node;
