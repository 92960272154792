/**
 * @generated SignedSource<<91af8c418f26a14206f46e6d482cde44>>
 * @relayHash f95607baa94b950e7eea64ef9c610cb6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/477.0.0-2024-08-23T22:21:23.075Z/addUserToAutopilotContactsMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddUserToAutopilotContactsInput = {
  clientMutationId?: string | null;
  sessionId: string;
  userId: string;
};
export type addUserToAutopilotContactsMutation$variables = {
  input: AddUserToAutopilotContactsInput;
};
export type addUserToAutopilotContactsMutation$data = {
  readonly addUserToAutopilotContacts: {
    readonly clientMutationId: string | null;
  } | null;
};
export type addUserToAutopilotContactsMutation = {
  response: addUserToAutopilotContactsMutation$data;
  variables: addUserToAutopilotContactsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddUserToAutopilotContactsPayload",
    "kind": "LinkedField",
    "name": "addUserToAutopilotContacts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addUserToAutopilotContactsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addUserToAutopilotContactsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/477.0.0-2024-08-23T22:21:23.075Z/addUserToAutopilotContactsMutation",
    "metadata": {},
    "name": "addUserToAutopilotContactsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f94c4bd4ad3841be78a022b6d736e5cd";

export default node;
