/**
 * @generated SignedSource<<1b1ac53d9cb5b197d0afa69a15768183>>
 * @relayHash aa40a7a7dbd3eedd347e1c0928c36bc3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/477.0.0-2024-08-23T22:21:23.075Z/userGeoInfoQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type userGeoInfoQuery$variables = {
  cookieDomain?: string | null;
  userCountryCode?: string | null;
  userId?: string | null;
};
export type userGeoInfoQuery$data = {
  readonly viewer: {
    readonly regionalInfo: ReadonlyArray<{
      readonly countryCode: string | null;
      readonly incomeLevel: {
        readonly incomeBracket: string | null;
        readonly incomePercentile: string | null;
      } | null;
      readonly isInEurope: boolean | null;
      readonly regionsByZipCode: ReadonlyArray<{
        readonly displayName: string | null;
        readonly urlLabel: string | null;
      } | null> | null;
      readonly zipCode: string | null;
    } | null> | null;
  };
};
export type userGeoInfoQuery = {
  response: userGeoInfoQuery$data;
  variables: userGeoInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "cookieDomain"
},
v1 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cookieDomain",
    "variableName": "cookieDomain"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zipCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "RegionsByZipCode",
  "kind": "LinkedField",
  "name": "regionsByZipCode",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlLabel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "IncomeLevel",
  "kind": "LinkedField",
  "name": "incomeLevel",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incomeBracket",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incomePercentile",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": "isInEurope",
  "args": [
    {
      "kind": "Literal",
      "name": "regionName",
      "value": "Europe"
    },
    {
      "kind": "Variable",
      "name": "userCountryCode",
      "variableName": "userCountryCode"
    }
  ],
  "kind": "ScalarField",
  "name": "isInRegion",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "userGeoInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "userGeoInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RegionalInfo",
            "kind": "LinkedField",
            "name": "regionalInfo",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/477.0.0-2024-08-23T22:21:23.075Z/userGeoInfoQuery",
    "metadata": {},
    "name": "userGeoInfoQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7c35b5756722a78478021df5ec3aafd6";

export default node;
